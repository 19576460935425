.wrapper{
    height: auto;

    margin: 0 auto 0 auto;
    padding: 0 0 20px 0;
}

.btn_back_quiz{
    border:3px solid #285CA6;
    font-weight: 800;
    text-align: center;
    color: #285CA6;
    line-height: 30px;
    width: 300px;
    height: 30px;
    border-radius: 20px;
    margin: 20px auto;
    cursor: pointer;
}

.message_not_question{
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    line-height: 39px;
    margin-top: 10px;
    color: #c43b12;
}

.bodyAsk{
    height: auto;
    max-width: 1450px;
    margin: auto;
}

@media (max-width: 770px) {
    .wrapper{
        
        margin: 30px auto 0 auto;

    }
    .quiz_name{
        padding-top: 10px;
        text-align: left;
    }
}
@media (max-width: 400px) {

    .quiz_name{
        text-align: center;
    }
}