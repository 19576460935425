.wrapper{
    padding :40px  20px 20px 20px;
}
.content{
    border:3px solid #285CA6;
    border-radius: 20px;
    width: 85%;
    max-width: 600px;
    margin: auto;
    padding: 20px;
}
.title{
    color:#285CA6;
    font-weight: 800;
    display: block;
    text-align: center;
    font-size: 24px;
}

.text{
    color:black;
    font-weight: 800;
    display: block;
    text-align: left;
    font-size: 20px;
    margin: 10px;
}
.img{
    display: block;
    width: 90%;
    margin: auto;
}
.wrapper_btn{
    text-align: center;
    margin-top: 20px;
}